@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Source+Code+Pro:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

// variables
$fondo: white;
$letra: black;
$detalle: rgba(0, 0, 0, 0.8);
$detalle8: rgba(0, 0, 0, 0.8);
$detalle6: rgba(0, 0, 0, 0.6);
$detalle4: rgba(0, 0, 0, 0.4);
$detalle2: rgba(0, 0, 0, 0.2);
$detalle2: rgba(0, 0, 0, 0);

// mixin
@mixin desktop {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: 767.9px) {
    @content;
  }
}

* {
  margin: 0;
  padding: 0;
  color: $letra;
  font-family: "Source code pro";
  letter-spacing: 0.7px;
}

.negrita {
  font-weight: bold;
}
.boton {
  background-color: $detalle;
  color: $fondo;
  text-transform: uppercase;
  font-size: 0.8rem;
  text-decoration: none;
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 1rem;
  cursor: pointer;
  box-shadow: 2px 2px 4px #888888;
  transition-duration: 0.4s;
  &:hover {
    opacity: 0.7;
    color: white;
    box-shadow: 4px 4px 8px #888888;
  }
  i {
    color: $fondo;
    margin: 0.5rem;
  }
}

// LOADER
.contenido {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0);
  padding-bottom: 5rem;
  i {
    position: absolute;
    font-size: 2rem;
    color: $letra;
  }
}

.spinner {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 5px solid $letra;
  background: transparent;
  border-top-color: $detalle6;
  border-bottom-color: $detalle6;
  border-right-color: $detalle6;
  border-left-color: $letra;
  animation: spin 4s linear infinite;
  position: relative;
}

@keyframes spin {
  0% {
    background: transparent;
    transform: rotate(720deg);
  }
}

// .file-select {
//   position: relative;
//   display: inline-block;
// }

// .file-select::before {
//   background-color: $detalle;
//   color: white;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 3px;
//   content: 'Seleccionar foto'; /* testo por defecto */
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
// }

// .file-select input[type="file"] {
//   opacity: 0;
//   width: auto;
//   height: auto;
//   display: inline-block;
// }
.cajaUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem;
}
.cuadro {
  border-bottom: 2px solid grey;
}
.btnCant {
  border: none;
  font-size: 0.8rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  transition-duration: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    box-shadow: 4px 4px 8px #888888;
  }
}
.gris {
  color: rgba(128, 128, 128, 0.7);
  font-size: 0.8rem;
}
.inputStock {
  width: 2rem;
  text-align: center;
}

.input-group {
  justify-content: flex-end;
}
.descripcion {
  p {
    margin-bottom: 0;
  }
}

.noStock {
  background-color: rgba(255, 0, 0, 0.2);
}
.imgMedida {
  position: relative;
  display: inline-block;
}

.medida {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: $detalle8;
  padding-right: 0.3rem;
  padding-top: 0.3rem;
  display: flex;
  justify-content: center; /* Para centrar horizontalmente */
  align-items: center;
  flex-direction: column;
  h5 {
    color: white;
    font-size: 1.5rem;
    margin: 0.5rem;
  }
  p {
    color: white;
    font-size: 1rem;
    font-weight: 300 !important;
  }
}
.stockCero {
  background-color: $detalle8;
  color: $fondo;
  padding: 0.5rem;
  border-radius: 1rem;
}
a {
  text-decoration: none !important;
}
.actividades {
  position: relative;
  .btnEspacio {
    min-height: calc((100vh - 9rem) / 4);
    // height: 100%;
    position: absolute;
    min-width: 33.33vw;
    width: 100%;
    top: 0;
    left: 0rem;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.5)
    );
    color: whitesmoke;
    padding: 2rem;
    padding-top: 3rem;
    font-size: 1rem;
    // border-radius: 2rem;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.5);
    transition-duration: 0.4s;

    &:hover {
      // opacity: 0.7;
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5), 0 4px 6px rgba(0, 0, 0, 0.08);
    }

    @include desktop {
      min-width: 33.33vw;
      width: auto;
      padding-top: 3rem;
    }
  }
  .sombra:hover {
    opacity: 0.6;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5), 0 4px 6px rgba(0, 0, 0, 0.08);
  }

  .wrap {
    flex-wrap: wrap;
  }
  .mostrar {
    max-height: calc((100vh - 9rem) / 4);
    overflow: hidden;
    transition: max-height 0s ease-out;
  }
}
.talleres {
  position: relative;
  h4 {
    display: flex;
    align-items: center; /* Centrar verticalmente */
    justify-content: center;
    min-height: 2rem;
    font-weight: 500 !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .cajaDeCajas {
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.5);
    transition-duration: 0.4s;
    &:hover {
      // opacity: 0.7;
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5), 0 4px 6px rgba(0, 0, 0, 0.08);
    }
  }
  .caja {
    position: relative;
    width: 100%; /* Or set a specific width */
    height: calc(50vw); /* Set the desired fixed height */
    overflow: hidden;
    @include desktop {
      height: calc(33.33vw / 2);
    }

    .prueba {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
    }

    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .btnTaller {
    text-align: center;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0rem;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0)
    );
    color: whitesmoke;
    padding: 2rem;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.5);
    transition-duration: 0.4s;

    &:hover {
      // opacity: 0.7;
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5), 0 4px 6px rgba(0, 0, 0, 0.08);
    }

    @include desktop {
      // width: auto;
      // padding-top: 3rem;
    }
  }
  .btnConsultar {
    cursor: pointer;
    border-radius: 0.5rem;
    padding: 0.5rem;
    // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 .5px 1px rgba(0, 0, 0, 0.5);
    transition-duration: 0.4s;

    &:hover {
      // opacity: 0.7;
      color: rgba(0, 0, 0, 0.8);
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5), 0 4px 6px rgba(0, 0, 0, 0.08);
    }
  }
  .sombra:hover {
    opacity: 0.6;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5), 0 4px 6px rgba(0, 0, 0, 0.08);
  }

  .wrap {
    flex-wrap: wrap;
  }
  .mostrar {
    max-height: calc((100vh - 9rem) / 4);
    overflow: hidden;
    transition: max-height 0s ease-out;
  }
  .titulo {
    display: flex;
    margin-top: 2rem;
    background-color: black;
    align-items: center;
    justify-content: center;
    height: 3rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    h1 {
      text-align: center;
      color: whitesmoke;
      font-weight: 400;
      font-size: 1rem;
    }
  }
}
.detalleTaller {
  margin: auto;
  max-width: 1500px;
  align-items: center;
  @include desktop {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .slick-track {
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
      aspect-ratio: 16/9;
      width: 100%;
      object-fit: cover;
    }
  }
  .card {
    border: none;
    padding: 0rem;
    @include desktop {
      padding: 0rem;
    }
  }
  .arancel {
    font-size: 0.8rem;
    @include desktop {
      font-size: 0.8rem;
    }
  }
  h1 {
    margin-top: 1rem;
    padding: 1rem;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    /* Establecer brillo */
    filter: brightness(1.2); /* Ajusta el valor según prefieras */

    /* Agregar sombra */
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);

    /* Establecer márgenes */
    margin-left: auto;
    margin-right: auto;

    @include desktop {
      font-size: 1rem;
      margin-top: 2rem;
    }
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 500;
  }
  h4 {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .datos {
    min-height: 0;
    display: flex;
    justify-content: space-between;
    @include desktop {
      min-height: 50vh;
    }
  }
  select:focus {
    outline: none;
  }
  .pModal {
    display: inline;
    cursor: pointer;
  }
}
.imgDesktop {
  width: 50%;
  float: right;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5), 0 4px 6px rgba(0, 0, 0, 0.08);
}

.formulario2 {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 0.2rem;
  label {
    padding-left: 1rem;
    font-weight: 500;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }
  input {
    padding-left: 1rem;
    border-radius: 1rem;
  }
  textarea {
    padding: 1rem;
    border-radius: 1rem;
  }
}
.htmlEn {
  margin: 0;
  p {
    margin: 0;
  }
}
