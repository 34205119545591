.cart{
    h1{
        font-size: .8rem;
        color: $detalle;
        @include desktop{
            font-size: 1rem;
        }
    }
    h2{
        font-size: 1.1rem;
        text-transform: uppercase;
    }
    .boton{
        width: fit-content;
        text-align: center;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .vaciar{
        background-color: rgb(167, 164, 164);
    }
    .botonera{
        align-items: center;
        flex-direction: column;
        display: flex;
        justify-content: center;
        @include desktop{
            flex-direction: row;
        }
    }
}