.item {
  // padding: .3rem;
  .link {
    text-decoration: none;
    img {
      @include desktop {
        // opacity: .3;
        &:hover {
          opacity: 1;
          box-shadow: 4px 4px 8px #888888;
          transition-duration: 0.4s;
        }
      }
    }
  }
  .row {
    --bs-gutter-x: 0rem;
  }
  .card {
    padding: 0.3rem;
  }
  .tachado {
    text-decoration: line-through !important;
    color: grey;
    font-size: 0.8rem;
  }
  .letraGris {
    color: grey;
    font-size: 0.8rem;
    font-weight: 200;
  }
  .card {
    border: none;
    border-radius: none;
  }
  .card-img {
    border: none;
    border-radius: none;
  }
  h4 {
    font-size: 0.8rem;

    text-transform: uppercase;

    display: flex;
    align-items: center; /* Centrar verticalmente */
    justify-content: center;
    min-height: 2rem;
    font-weight: 500 !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  p {
    font-size: 1rem;
    font-weight: bold;
  }
  .cartel {
    display: flex;
    justify-content: flex-end;
    .seisSin {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      color: white;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .ahorro {
      background-color: black;
      width: fit-content;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      color: whitesmoke;
      padding-left: 1rem;
      padding-right: 1rem;
      align-self: flex-end;
      font-size: 0.9rem;
    }
  }
}
.detalle {
  margin: auto;
  max-width: 1000px;
  align-items: center;
  .card {
    border: none;
    padding: 0rem;
    @include desktop {
      padding: 2rem;
    }
  }

  h1 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
  }
  h2 {
    font-size: 1.2rem;
    font-weight: 500;
  }
  h4 {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .datos {
    min-height: 0;
    display: flex;
    justify-content: space-between;
    @include desktop {
      min-height: 50vh;
    }
  }
  select:focus {
    outline: none;
  }
  .pModal {
    display: inline;
    cursor: pointer;
  }
}
#agregarAlCarrito {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  @include desktop {
    flex-direction: row;
  }
  a {
    width: fit-content;
  }
}
.itemDetail {
  max-width: 1000px;
}
.tienda {
  .slick-track {
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
      aspect-ratio: 1/1;
      width: 100%;
      object-fit: cover;
    }
  }
  .cajaDeCajas {
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.5);
    transition-duration: 0.4s;
    &:hover {
      // opacity: 0.7;
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5), 0 4px 6px rgba(0, 0, 0, 0.08);
    }
  }
  .caja {
    position: relative;
    width: 100%; /* Or set a specific width */
    padding-top: 100%;
    overflow: hidden;
    img {
      aspect-ratio: 1/1;
      width: 100%;
      object-fit: cover;
    }

    .prueba {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
    }

    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
